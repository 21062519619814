import React, { useState } from "react";
import moment from "moment";
import styles from "../../assets/stylesheets/pages/tools/tools.module.scss";
import ProjectTitle from "../../components/common/ProjectTitle";
import Datepicker from "../../uicomponents/date-picker/datepicker";
import FormInput from "../../uicomponents/form-input/forminput";
import FutworkButton from "../../uicomponents/button/Button";
import { getProjectReports } from "../project/main/service";
import ApiActions from "../../actions/ApiActions";
import Popup from "../../uicomponents/popup";
import { InfoIcon, TrashIcon } from "../../assets/images"
import { cloneDeep, set } from 'lodash';
import { ToastContainer, toast } from "react-toastify";

// const BASE_URL = "https://storage.googleapis.com/futwork-reports";
const BASE_URL = "https://storage.googleapis.com/futwork-staging-uploads";

const MergeReport = (props) => {
  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");
  const [projectInputs, setProjectInputs] = useState([
    { id: 1, value: "", fetched: false, fetching: false, reportUrl: "" },
  ]);
  const [centralData, setCentralData] = useState(projectInputs)
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState({
    status: '', msg: ''
  });
  const [isMerging, setIsMerging] = useState(false);
  const [isReportSent, setIsReportSent] = useState(false)

  const handleInputChange = (index, value) => {
    const updatedInputs = cloneDeep(projectInputs);
    updatedInputs[index].value = value;
    setProjectInputs(updatedInputs);
  };

  const addNewInputField = () => {
    setProjectInputs([
      ...projectInputs,
      {
        id: projectInputs.length + 1,
        value: "",
        fetched: false,
        fetching: false,
        reportUrl: "",
      },
    ]);
    setCentralData([
      ...centralData,
      {
        id: centralData.length + 1,
        value: "",
        fetched: false,
        fetching: false,
        reportUrl: "",
      },
    ])
  };

  const handleDelete = (index) => {
    setProjectInputs(projectInputs.filter((_, i) => i !== index));
    setCentralData(centralData.filter((_, i) => i !== index))
  };

  const checkReportAvailability = async(id, reportName, immediate = false) => {
    const fetchData = async () => {
      try {
        const encodedReportName = encodeURIComponent(reportName);
        const reportUrl = `${BASE_URL}/${encodedReportName}`;
        const response2 = await ApiActions.axiostunnel({ url: reportUrl, method: "HEAD" });

        if (response2.status === 200) {
          setProjectInputs((prev) =>
            prev.map((f) =>
              f.id === id ? { ...f, fetching: false, fetched: true, reportUrl } : f
            )
          );
          return true; // Stops polling if result is found
        }
      } catch (error) {
        console.error("Second API error:", error);
      }
      return false; // Continue polling if no result
    };

    if (immediate) {
      const isCompleted = await fetchData();
      if (isCompleted) return;
    }

    const intervalId = setInterval(async () => {
      const isCompleted = await fetchData();
      if (isCompleted) clearInterval(intervalId);
    }, 30000);
  };
 
  const handleFetch = async (id, projectid) => {
    if(!reportStartDate || !reportEndDate){
      toast("Please select the date range")
      return console.error("Please select the date range");
    }
    if (!projectid) {
      toast("Project ID is required")
      return console.error("Project ID is required");
    }
    setProjectInputs((prev) =>
      prev.map((field) => (field.id === id ? { ...field, 'fetching': true } : field))
    );

    try {
      const result = await getProjectReports({
        teleproject: projectid,
        startDate: moment(reportStartDate).format("YYYY-MM-DD"),
        endDate: moment(reportEndDate).format("YYYY-MM-DD"),
        isLite: true,
      });
      const reportName = result.data.data.reportName;
      if(reportName){
        setProjectInputs((prev) =>
          prev.map((f) => (f.id === id ? { ...f, reportName } : f))
        );
          // Start polling second API
        checkReportAvailability(id, reportName, true);
      }
    } catch (error) {
       console.error("First API error:", error);
      //set the ui state with error
      setProjectInputs((prev) =>
        prev.map((field) => (field.id === id ? { ...field, 'fetching': false } : field))
      );
    }
  };

  const handleMerge = async () => {
    setPopupMessage({ status: '', msg: '' });
  
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = (email) => emailRegex.test(email);
  
    if (!email || !isValidEmail(email)) {
      setPopupMessage({ status: 'error', msg: "Please enter a valid email address." });
      return;
    }
  
    const reportUrls = projectInputs
      .filter((input) => input.reportUrl) // Only include inputs that have fetched URLs
      .map((input) => ({
        url: input.reportUrl,
        projectId: input.value, // Assuming input.value holds the project ID
      }));
  
    if (reportUrls.length < 2) {
      setPopupMessage({ status: 'error', msg: "At least two reports must be fetched before merging." });
      return;
    }
  
    setIsMerging(true);
    try {
      await ApiActions.mergeReport({
        reporturls: reportUrls, // Send as an array of objects
        emails: email, // Updated key to "emails"
      });
  
      setIsReportSent(true);
      setPopupMessage({ status: 'success', msg: "The report will be sent to your email within 10-15 minutes." });
    } catch (error) {
      console.error("Error merging reports:", error);
      setPopupMessage({ status: 'error', msg: "Failed to merge reports. Please try again." });
    } finally {
      setIsMerging(false);
    }
  };

  // const handleMerge = async () => {
  //   setPopupMessage({status: '', msg: ''})
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   const isValidEmail = (email) => emailRegex.test(email);
  //   if (!email || !isValidEmail(email)) {
  //     setPopupMessage({status: 'error', msg:"Please enter a valid email address."});
  //     return;
  //   }

  //   const reportUrls = projectInputs.map((input) => input.reportUrl).filter(Boolean);
  //   if (reportUrls.length < 2) {
  //     setPopupMessage({status: 'error', msg:"At least two reports must be fetched before merging."});
  //     return;
  //   }

  //   setIsMerging(true);
  //   try {
  //     await ApiActions.mergeReport({ urls: reportUrls, email });
  //     setIsReportSent(true)
  //     setPopupMessage({status: 'success', msg:"The report will be sent to your email within 10-15 minutes."});
  //   } catch (error) {
  //     console.error("Error merging reports:", error);
  //     setPopupMessage({status: 'error', msg: "Failed to merge reports. Please try again."});
  //   }finally{
  //     setIsMerging(false)
  //   }
  // };

  const checkIfReportsAreFetched = () => {
    const fetchedURlCount = projectInputs && projectInputs.filter(itm => itm.fetched).length
    const uiCount = projectInputs && projectInputs.length
    if(fetchedURlCount === uiCount) return false
    else return true
  }

  const checkIfEligibleForMerging = () => {
    if(isMerging)
    return true
  }

  return (
    <div className="container">
      <div className={styles.page_wrapper}>
        <div className={styles['mergereport_tool_wrapper']}>
        <ProjectTitle title="Back" goBack={() => props.history.push("/tools")} />
        <div className={styles['title_row']}>Merge Report Tool</div>
        <div className={styles['user_note']}>
          <div className={styles.info_icon}><img src={InfoIcon}/> 
            <u><b>Note:</b></u>
          </div>
          <ul className={styles.list}>
            <li>
              <div className={styles.headnote}><span>The Project ID must belong to the same brand</span></div>
            </li>
          </ul>
        </div>
        
        <div className={styles.datepicker}>
          <div className={styles.dateselection}>
            <div className={styles.label}>Start Date:</div>
            <Datepicker selectedDate={reportStartDate} dateFormat="dd/MM/yyyy" onChange={setReportStartDate} maxDate={moment(reportEndDate).toDate()} />
          </div>
          <div className={styles.dateselection}>
          <div className={styles.label}>End Date:</div>
          <Datepicker selectedDate={reportEndDate} dateFormat="dd/MM/yyyy" onChange={setReportEndDate} 
          minDate={moment(reportStartDate).toDate()} />
          </div>
        </div>

        <div className={styles.mergereport}>
          {projectInputs.map((input, index) => (
            <div key={input.id} className={styles.fetchrow}>
              <div className={styles.formwidth}>
                <FormInput label="Project Id" value={input.value} onChange={(e) => handleInputChange(index, e.target.value)} />
              </div>
              <FutworkButton buttonStyle={input.fetched ? "success--solid" : "primary--solid"} onClick={() => handleFetch(index+1, input.value)} disabled={input.fetching || input.fetched}>
                {input.fetching ? "Fetching..." : input.fetched ? "Fetched" : "Fetch"}
              </FutworkButton>
              {/* <FutworkButton buttonStyle="danger--solid" onClick={() => handleDelete(index)}>Delete</FutworkButton> */}
              <img src={TrashIcon} className={styles.deleterow} onClick={() => handleDelete(index)}/>
            </div>
          ))}
          <div className={styles.addnew} onClick={() => addNewInputField()}>+ Add New</div>
        </div>
        <FutworkButton buttonStyle="primary--solid" buttonSize="sm" onClick={() => setShowPopup(true)} disabled={checkIfReportsAreFetched()}>Merge All</FutworkButton>
        </div>
      </div>
      <Popup show={showPopup} closePopup={() => {
        setShowPopup(false)
        setIsMerging(false)
        setIsReportSent(false)
      }} size="sm">
        <div className={styles.popupContent}>
          {isReportSent ? null :
          <div>
          <FormInput label="Email" value={email} onChange={(e) => setEmail(e.target.value)} 
            placeholder={"Enter email address for sending the report"}/>
          <FutworkButton buttonStyle="primary--solid" onClick={handleMerge} disabled={checkIfEligibleForMerging()}>
            {isMerging ? "Merging..." : "Submit"}
          </FutworkButton>
          </div>}
          {popupMessage && <p className={popupMessage.status == 'success' ? styles['success-msg'] : styles['error-msg']}>{popupMessage.msg}</p>}
        </div>
      </Popup>
			<ToastContainer draggable={true} />
    </div>
  );
};

export default MergeReport;





